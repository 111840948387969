import React from 'react';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from '../components/PageBox';
import SectionNotification from '../components/SectionNotification';
import SEO from '../components/SEO';

export default function NotificationPage(props) {
  return (
    <PageBox>
      <SEO
        pageData={{
          title: 'Уведомление об изменении реквизитов счета в банке',
        }}
      />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Уведомление'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />
      <SectionNotification />
    </PageBox>
  );
}