import React, { useMemo } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import SectionContainer from '@product-site-frontend/shared/components/SectionContainer';

import qrRequisites from './images/qr-requisites.jpg';
import tinkofBusinessLogo from './images/tinkoff-business.png';

export default function SectionNotification() {
  const PAYMENT_DETAILS = useMemo(() => ([
    { label: 'Банк', value: 'АО «Тинькофф-Банк' },
    { label: 'ИНН', value: '7703413614' },
    { label: 'БИК банка', value: '044525974' },
    { label: 'Корреспондентский счет банка', value: '30101810145250000974' },
    { label: 'Расчетный счет', value: '40701810700000007106' },
  ]), []);

  const styles = useMemo(() => ({
    sectionBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: { xs: '90%', lg: '70%' },
      margin: 'auto',
      px: { xs: 1, lg: 9 },
    },
    sectionTitle: {
      color: 'primary.main',
      fontSize: { xs: 36, lg: 48 },
      fontWeight: 900,
      letterSpacing: -0.5,
      lineHeight: { xs: 1.2, lg: 1.35 },
      mb: { xs: 4, lg: 8 },
      textAlign: 'center',
    },
    imagesBox: {
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: 1,
      mt: { xs: 4, lg: 9 },
      mb: { xs: 7, lg: 9 },
    },
    leftImageBox: {
      width: { xs: 1, lg: '50%' },
      display: 'flex',
      justifyContent: { xs: 'center', lg: 'flex-start' },
    },
    rightImageBox: {
      width: { xs: 1, lg: '50%' },
      display: 'flex',
      justifyContent: { xs: 'center', lg: 'flex-end' },
    },
  }), []);

  return (
    <SectionContainer>
      <Box sx={styles.sectionBox}>
        <Typography sx={styles.sectionTitle}>
          Уведомление об изменении реквизитов счета в банке
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 22, lg: 24 }, lineHeight: '40px', mb: 9 }}
        >
          Уважаемые клиенты, обратите внимание, что с 24.01.2022{' '}
          в ООО &quot;АРЕНЗА-ПРО&quot; обновились реквизиты счета{' '}
          в АО &quot;Тинькофф-Банк&quot;.
          <br /><br />
          Просим перечислять денежные средства по новому расчетному счету:{' '}
          <Typography
            component="span"
            sx={{ fontSize: { xs: 22, lg: 24 }, fontWeight: 700, mt: 1 }}
          >
            40701810700000007106
          </Typography>. Уже совершенные платежи будут зачислены на{' '}
          корректные реквизиты.
          <br /><br />
          При перечислении платежа обязательно указывайте в назначении{' '}
          номер и дату договора лизинга.
        </Typography>
        <Box sx={{ width: 1 }}>
          <Typography
            sx={{ fontSize: { xs: 22, lg: 24 }, fontWeight: 700, mb: 1 }}
          >
            Банковские реквизиты
          </Typography>
          {PAYMENT_DETAILS.map(({ label, value }) => (
            <Typography
              key={label}
              sx={{ fontSize: { xs: 22, lg: 24 }, fontWeight: 700, mb: 2 }}
            >
              {label}{': '}
              <Typography fontWeight="300" variant="text">
                {value}
              </Typography>
            </Typography>
          ))}
        </Box>
        <Box sx={styles.imagesBox}>
          <Box sx={styles.leftImageBox}>
            <img
              alt={'ТИНЬКОФФ БИЗНЕС'}
              src={tinkofBusinessLogo}
              width="354px"
            />
          </Box>
          <Box sx={styles.rightImageBox}>
            <img
              alt={'QR-код'}
              src={qrRequisites}
              width="222px"
            />
          </Box>
        </Box>
        <Button
          color="primary"
          component="a"
          href={`${process.env.GATSBY_ROOT_URL}/bank_details_pdf`}
          size="large"
          sx={{ fontSize: 20, textTransform: 'none' }}
          target="_blank"
          variant="contained"
        >
          PDF реквизиты
        </Button>
      </Box>
    </SectionContainer>
  );
}